<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="专家名称" prop="doctorName">
              <el-input
                v-model="form.doctorName"
                placeholder="请输入专家名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="头衔" prop="title">
              <el-input
                v-model="form.title"
                placeholder="请输入头衔"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="博士排序" prop="doctorSort">
              <el-input
                v-model="form.doctorSort"
                placeholder="请输入博士排序"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标签" prop="tags">
              <el-input
                v-model="form.tags"
                placeholder="请输入标签,用逗号（ , ）分割"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="" prop="tags">
              <span style="width: 100%; color: #f00;margin-left:-80px;">（ 标签用逗号分割 ）</span>
            </el-form-item>
          </el-col>
          
          <el-col :span="12">
            <el-form-item label="小头像" prop="smallImage">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :action="'/api/admin/public/uploadImg/expert'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :data="{
                  width: 100,
                }"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="form.smallImage"
                  :src="form.smallImage"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="大图" prop="largeImage">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :action="'/api/admin/public/uploadImg/expert'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess2"
                :data="{
                  width: 750,
                }"
                :before-upload="beforeAvatarUpload2"
              >
                <img
                  v-if="form.largeImage"
                  :src="form.largeImage"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="描述" prop="description">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入描述"
                v-model="form.description"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/assets/api/config.js";
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增博士信息",
      form: {
        description: "",
        doctorName: "",
        doctorSort: 0,
        largeImage: "",
        smallImage: "",
        tags: "",
        title: "",
      },
      headers: {
        token: localStorage.getItem("token"),
      },
      config,
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.form.smallImage = res.data.downloadUrl;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess2(res, file) {
      console.log(res, file);
      this.form.largeImage = res.data.downloadUrl;
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    submitData() {
      let params = JSON.parse(JSON.stringify(this.form));
      let url = "";
      if (params.doctorId) {
        //修改
        url = "api/admin/sys/main/updateDoctor";
      } else {
        url = "api/admin/sys/main/addDoctor";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增博士信息";
      this.form = {};
    },
    getInfo(info) {
      this.dialogTitle = "编辑博士信息";
      this.form = info;
      this.visible = true;
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
